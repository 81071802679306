import { Container, CssBaseline } from '@mui/material';
import Box from '@mui/material/Box';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useBetslip } from '../../contexts/BetslipContext';
import { getSidebarType } from '../../helpers';
import useBreakpoints from '../../hooks/useBreakpoints';
import useWinningJackpot from '../../hooks/useWinningJackpot';
import { useCountPendingBetslips } from '../../queries';
import DrawerNavigation from '../molecules/DrawerNavigation';
import Header from '../molecules/Header';
import JackpotAnimation from '../molecules/JackpotAnimation';
import BetslipDrawer from '../organisms/BetslipDrawer';
import CMSSideContent from '../organisms/CMSSideContent';
import ReofferedBetslipDrawer from '../organisms/ReofferedBetslipDrawer';
import { useIFrameData } from '../../hooks/useIFrameData';

const styles = {
  container: { display: 'flex', width: '100%' },
  main: {
    height: '100vh',
    flexGrow: 1,
    overflowX: 'hidden',
  },
};

const MainLayout = ({ children }: PropsWithChildren) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { isMobileViewGameOpen } = useIFrameData();
  const { isMobile, isTablet } = useBreakpoints();
  const { joinUserRoom, setBettingPrevented } = useBetslip();

  const { clearJackpot, winningJackpot } = useWinningJackpot();

  useCountPendingBetslips({
    onSuccess: (data) => {
      setBettingPrevented(data?.count > 0);
    },
  });

  const location = useLocation();
  const sidebarType = getSidebarType(location);

  const toggleDrawerVisibility = (isOpen: boolean) => {
    setIsDrawerOpen(isOpen);
  };

  useEffect(() => {
    joinUserRoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fullWidth = window.location.href?.includes('casino') && !window.location.href?.includes('history');

  return (
    <Container
      disableGutters
      sx={{
        ...styles.container,
        maxWidth: fullWidth ? '100vw !important' : '1900px !important',
        mx: fullWidth ? 0 : 'auto',
      }}
    >
      <CssBaseline />
      {!isMobileViewGameOpen && <Header toggleDrawerVisibility={toggleDrawerVisibility} />}
      {sidebarType && (
        <DrawerNavigation isOpen={isDrawerOpen} toggleVisibility={toggleDrawerVisibility} sidebarType={sidebarType} />
      )}
      {!!winningJackpot && <JackpotAnimation winningJackpotPool={winningJackpot} clearJackpot={clearJackpot} />}
      <Box
        width={1}
        component='main'
        sx={{
          ...styles.main,
          px: isMobile || fullWidth ? 0 : 2,
          pt: isMobileViewGameOpen ? 0 : 8,
        }}
      >
        {children}
      </Box>
      {location.pathname === '/sports' && !isTablet && <CMSSideContent />}
      {!isMobileViewGameOpen && <BetslipDrawer />}
      <ReofferedBetslipDrawer />
    </Container>
  );
};

export default MainLayout;
