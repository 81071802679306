import { Typography } from '@mui/material';

type Props = {
  size?: 'small' | 'medium';
};

const Logo = ({ size = 'medium' }: Props) => {
  return (
    <Typography variant={size === 'small' ? 'h3' : 'h1'} color='secondary'>
      LOGO
    </Typography>
  );
};

export default Logo;
