import { AccountCircle, ArrowDropDown, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import omit from 'lodash-es/omit';
import { useAuth } from 'neofusion-fe-shared';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyleObj, UserSettings } from '../../../@types';
import { ACCOUNT_MENU_ITEM_CONFIG, ACCOUNT_TABS, AccountMenuTab } from '../../../config/accountMenu';
import { CURRENCY, QUERY_KEYS } from '../../../constants';
import { getBalanceAmount } from '../../../helpers';
import useBreakpoints from '../../../hooks/useBreakpoints';
import { useInvalidateQuery } from '../../../hooks/useInvalidateQuery';
import useTabs from '../../../hooks/useTabs';
import { useBalances } from '../../../queries';
import { getData, patchData } from '../../../utils/api';
import ReloadIcon from '../../icons/ReloadIcon';

const styles: StyleObj = {
  username: {
    display: {
      xs: 'none',
      md: 'block',
    },
  },
  balance: {
    fontSize: { xs: 9, md: 12 },
    textAlign: 'right',
  },
  accountButton: {
    p: 0,
    m: 0,

    cursor: 'pointer',
    color: (theme) => theme.palette.primary.light,
    '& svg': {
      fontSize: {
        xs: 24,
        md: 32,
      },
    },
  },
  iconButton: {
    '&:hover': {
      background: 'transparent',
    },
  },
  menuContainer: {
    mt: 2,
    '& .MuiPaper-root': {
      width: 360,
      backgroundColor: 'neutral.25',
      color: 'neutral.800',
    },
    '& .MuiList-root': {
      py: 0,
    },
  },
  tab: {
    px: 0.5,
    textTransform: 'none',
    '& svg': {
      flex: 1,
    },
    '&.Mui-selected': {
      backgroundColor: 'neutral.50',
      '& p': {
        fontWeight: 600,
      },
    },
  },
  accordion: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:before': { display: 'none' },
  },
  accordionDetails: { flexDirection: 'column', p: 0 },
  menuItem: {
    backgroundColor: 'neutral.50',
    borderBottom: '1px solid',
    borderColor: 'neutral.100',
    minHeight: 40,
    '&:hover': { backgroundColor: 'neutral.200' },
  },
  childMenuItem: {
    backgroundColor: 'neutral.100',
    '&:hover': { backgroundColor: 'neutral.200' },
    flex: 1,
    '&.MuiMenuItem-root': {
      minHeight: 40,
      '&:not(:last-child)': {
        borderBottom: '1px solid',
        borderColor: 'neutral.200',
      },
    },
  },
  logoutButton: {
    py: 1.5,
    backgroundColor: 'neutral.25',
    ':hover': {
      border: 'none',
    },
  },
  accountLabel: {
    width: '84px',
    '& svg': {
      height: '32px',
      width: '32px',
    },
    cursor: 'pointer',
    '&:hover': {
      color: 'primary.main',
    },
  },
};

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showBalance, setShowBalance] = useState(false);
  const { isMobile } = useBreakpoints();
  const navigate = useNavigate();
  const { handleLogout, username } = useAuth();
  const tabProps = useTabs<AccountMenuTab>({ tabConfig: ACCOUNT_TABS });
  const invalidateData = useInvalidateQuery();

  const { data: userSettings } = useQuery({
    queryKey: [QUERY_KEYS.userSettings],
    queryFn: (): Promise<UserSettings> => getData('user-settings'),
  });

  const { data: balancesData } = useBalances();

  const mainBalance = getBalanceAmount(balancesData?.items, 'main');
  const bonusBalance = getBalanceAmount(balancesData?.items, 'bonus');

  const { mutate: updateUserSettings } = useMutation({
    mutationFn: (settings: UserSettings) => patchData('user-settings', settings),
    onSuccess: () => {
      invalidateData([QUERY_KEYS.userSettings]);
    },
  });

  const handleAccountMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleAccountMenuClose();
    handleLogout();
  };

  const handleMenuItemClick = ({ to, value, key }: { to?: string; value?: string; key?: string }) => {
    if (!to && !key && !value) return;

    handleAccountMenuClose();

    if (to) {
      return navigate(to);
    }

    if (key && value) {
      updateUserSettings({
        [value]: key,
      });
    }
  };

  const renderBalanceAndUsername = showBalance ? CURRENCY.symbol + mainBalance?.toFixed(2) : '';
  const renderBonusBalance = showBalance && bonusBalance ? `${CURRENCY.symbol}${bonusBalance.toFixed(2)}` : '';
  const openAccountMenu = Boolean(anchorEl);

  if (!userSettings) return null;

  return (
    <>
      <Stack direction={isMobile ? 'column-reverse' : 'row'} spacing={0.5} ml='auto'>
        {renderBalanceAndUsername && (
          <Box>
            <Typography variant='h6' sx={styles.username}>
              {username}
            </Typography>
            <Typography variant='body3' sx={styles.balance}>
              {renderBalanceAndUsername}
            </Typography>
          </Box>
        )}
        <IconButton
          aria-haspopup='true'
          edge='end'
          aria-label='menu'
          onClick={handleAccountMenuOpen}
          sx={styles.accountButton}
        >
          <AccountCircle color='info' />
        </IconButton>
      </Stack>
      {openAccountMenu && (
        <Menu
          anchorEl={anchorEl}
          open
          onClose={handleAccountMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          elevation={2}
          sx={styles.menuContainer}
        >
          <Stack direction='row' justifyContent='space-between' px={1.5} py={1}>
            <Stack spacing={0.5}>
              {renderBalanceAndUsername && (
                <>
                  <Typography variant='body3'>{username}</Typography>
                  <Typography variant='body3' color='primary' fontWeight={600}>
                    {renderBalanceAndUsername}
                  </Typography>
                </>
              )}
            </Stack>
            <Stack direction='row' alignItems='center'>
              <IconButton onClick={() => setShowBalance((prev) => !prev)} sx={styles.iconButton}>
                {showBalance ? <VisibilityOutlined fontSize='small' /> : <VisibilityOffOutlined fontSize='small' />}
              </IconButton>
              <IconButton sx={styles.iconButton}>
                <ReloadIcon />
              </IconButton>
            </Stack>
          </Stack>
          <Stack px={1.5} py={1} spacing={0.5}>
            {renderBonusBalance && (
              <>
                <Typography variant='body3'>Bonus credits:</Typography>
                <Typography variant='body3' fontWeight={600}>
                  {renderBonusBalance}
                </Typography>
              </>
            )}
          </Stack>
          <Divider />
          <Tabs variant='fullWidth' {...omit(tabProps, 'setValue')} sx={styles.tabs}>
            {ACCOUNT_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={<Typography variant='body3'>{tab.label}</Typography>}
                value={tab.value}
                sx={styles.tab}
              />
            ))}
          </Tabs>
          <Divider />
          <MenuList>
            {tabProps.value === 'my-account' ? (
              <Stack direction='row' mx={1} my={3} flexWrap='wrap'>
                {ACCOUNT_MENU_ITEM_CONFIG[tabProps.value].map(({ label, to, icon }) => (
                  <Stack
                    onClick={() => handleMenuItemClick({ to })}
                    key={label}
                    direction='column'
                    alignItems='center'
                    gap={1}
                    sx={styles.accountLabel}
                  >
                    {!!icon && icon}
                    <Typography
                      variant='body2'
                      fontWeight={600}
                      flex={1}
                      flexWrap='wrap'
                      textAlign='center'
                      width='100%'
                    >
                      {label}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            ) : (
              ACCOUNT_MENU_ITEM_CONFIG[tabProps.value].map(({ value, to, sectionLabel, children }) => (
                <Accordion disableGutters key={value} onClick={() => handleMenuItemClick({ to })} sx={styles.accordion}>
                  <AccordionSummary
                    expandIcon={children?.length && <ArrowDropDown fontSize='small' />}
                    sx={styles.menuItem}
                  >
                    <Stack direction='column'>
                      {sectionLabel && <Typography variant='body3'>{sectionLabel}</Typography>}
                      <Typography variant='body2' fontWeight={600} flex={1}>
                        {children?.find((item) => item.key === userSettings[value as keyof UserSettings])?.label}
                      </Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails sx={styles.accordionDetails}>
                    {children?.map(({ label, key }) => (
                      <MenuItem
                        key={label}
                        onClick={() => handleMenuItemClick({ value, key })}
                        disableRipple
                        sx={styles.childMenuItem}
                      >
                        <Typography variant='body2' fontWeight={600} flex={1}>
                          {label}
                        </Typography>
                      </MenuItem>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))
            )}
            <MenuItem disableRipple sx={styles.logoutButton} onClick={handleLogoutClick}>
              <Typography variant='body2' fontWeight={600}>
                Logout
              </Typography>
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </>
  );
};

export default AccountMenu;
