import React from 'react';
import { BetslipStatus } from '../../@types';
import { Stack, Typography } from '@mui/material';
import { CURRENCY, BET_HISTORY_ACTION_CELL_MAP } from '../../constants';

const HistoryStatusCell = ({ status, winnings }: { status: BetslipStatus; winnings: number | null }) => {
  return (
    <Stack alignItems='center'>
      {status === 'won' && (
        <Typography variant='body2' fontWeight={600}>
          {CURRENCY.symbol}
          {winnings?.toFixed(2)}
        </Typography>
      )}
      <Typography color={BET_HISTORY_ACTION_CELL_MAP[status].color}>
        {BET_HISTORY_ACTION_CELL_MAP[status].text}
      </Typography>
    </Stack>
  );
};

export default HistoryStatusCell;
