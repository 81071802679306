import { Theme, styled } from '@mui/material';
import { MaterialDesignContent } from 'notistack';

const commonStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.neutral[800],
  top: '25vh',
  width: '100%',
  borderRadius: 4,
  padding: '32px 24px',
  boxShadow: 'none',
  textAlign: 'center',
  '& > *': {
    margin: 'auto',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '16px 12px',
  },
});

const StyledSnackbar = styled(MaterialDesignContent)(({ theme }) => ({
  '&.notistack-MuiContent-success': {
    ...commonStyles(theme),
    color: theme.palette.success.light,
    border: `1px solid ${theme.palette.success.light}`,
  },
  '&.notistack-MuiContent-info': {
    ...commonStyles(theme),
    border: `1px solid ${theme.palette.neutral[100]}`,
  },
  '&.notistack-MuiContent-warning': {
    ...commonStyles(theme),
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
  '&.notistack-MuiContent-error': {
    ...commonStyles(theme),
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
}));

export default StyledSnackbar;
