import { Box, Stack, Typography } from '@mui/material';
import { BetWithMarketOutcomeEvent, SpecialValue, StyleObj } from '../../@types';
import { getReofferedColor } from '../../utils';

const makeStyles = (isWaysEvent: boolean, isBanker?: boolean): StyleObj => ({
  container: isWaysEvent
    ? {
        position: 'relative',
        '::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          width: '5px',
          backgroundColor: (theme) => theme.palette.primary.main,
        },
      }
    : {},
  betInfo: { py: 1, px: 1.5 },
  oddsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previousValues: {
    textDecoration: 'line-through',
    opacity: 0.5,
  },
  bankerContainer: {
    display: 'grid',
    placeItems: 'center',
  },
  banker: {
    display: 'grid',
    placeItems: 'center',
    color: isBanker ? 'white' : 'neutral.600',
    borderRadius: 0,
    height: 48,
    width: 48,
    background: (theme) => (isBanker ? theme.palette.info[700] : 'white'),
    fontWeight: 600,
    pl: 1,
  },
});

type ReofferedBetslipItemProps = {
  bet: BetWithMarketOutcomeEvent;
  previousBet: BetWithMarketOutcomeEvent;
  isWaysEvent: boolean;
  bets: BetWithMarketOutcomeEvent[];
  showBankers: boolean;
};

const renderSpecialValues = (specialValues: SpecialValue[]) => {
  if (!specialValues || specialValues.length === 0) {
    return '';
  }

  return specialValues.reduce((acc, { value }) => (acc += `(${value}) `), '');
};

const ReofferedBetslipItem = ({ bet, previousBet, isWaysEvent, showBankers }: ReofferedBetslipItemProps) => {
  const specialValues = renderSpecialValues(bet.specialValues);
  const previousSpecialValues = renderSpecialValues(previousBet.specialValues);

  const isBanker = bet.banker || false;
  const styles = makeStyles(isWaysEvent, isBanker);

  const { color: oddsColor } = getReofferedColor(bet.odds, previousBet.odds);
  const { color: specialValuesColor, changed: specialValuesChanged } = getReofferedColor(
    bet.specialValues,
    previousBet.specialValues
  );

  return (
    <Stack direction='row' sx={styles.container}>
      <Stack direction='row' justifyContent='space-between' flexGrow={1} sx={styles.betInfo}>
        <Stack spacing={0.5} flexGrow={1} textAlign='left' pr={1} pl={0.5}>
          <Stack spacing={0.5} direction='row' alignItems='center'>
            <Typography variant='subtitle2' color='neutral.600'>
              {bet.outcome.shortName || bet.outcome.name}
            </Typography>
            {specialValuesChanged && (
              <Typography variant='subtitle2' color='neutral.600' sx={styles.previousValues}>
                {previousSpecialValues}
              </Typography>
            )}
            <Typography variant='subtitle2' color={specialValuesColor}>
              {specialValues}
            </Typography>
          </Stack>
          <Typography variant='h6' color='neutral.600'>
            {bet.event.name}
          </Typography>
          <Typography variant='body2' color='neutral.600'>
            {bet.market.shortName || bet.market.name}
          </Typography>
        </Stack>
        <Box sx={styles.oddsContainer}>
          {previousBet.odds !== bet.odds && (
            <Typography variant='subtitle2' height='fit-content' color='neutral.700' sx={styles.previousValues}>
              {previousBet.odds}
            </Typography>
          )}
          <Typography variant='subtitle2' color={oddsColor}>
            {bet.odds}
          </Typography>
        </Box>
      </Stack>
      {showBankers && (
        <Box sx={styles.bankerContainer}>
          <Box sx={styles.banker}>
            <Typography variant='h5'>B</Typography>
          </Box>
        </Box>
      )}
    </Stack>
  );
};

export default ReofferedBetslipItem;
