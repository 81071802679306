import { ReactComponent as Badminton } from '../assets/icons/badminton.svg';
import { ReactComponent as Baseball } from '../assets/icons/baseball.svg';
import { ReactComponent as Basketball } from '../assets/icons/basketball.svg';
import { ReactComponent as Boxing } from '../assets/icons/boxing.svg';
import { ReactComponent as Cricket } from '../assets/icons/cricket.svg';
import { ReactComponent as Darts } from '../assets/icons/darts.svg';
import { ReactComponent as Esports } from '../assets/icons/esports.svg';
import { ReactComponent as Floorball } from '../assets/icons/floorball.svg';
import { ReactComponent as Futsal } from '../assets/icons/futsal.svg';
import { ReactComponent as Golf } from '../assets/icons/golf.svg';
import { ReactComponent as Handball } from '../assets/icons/handball.svg';
import { ReactComponent as IceHockey } from '../assets/icons/ice_hockey.svg';
import { ReactComponent as MMA } from '../assets/icons/mma.svg';
import { ReactComponent as RugbyLeague } from '../assets/icons/rugby_league.svg';
import { ReactComponent as Snooker } from '../assets/icons/snooker.svg';
import { ReactComponent as Soccer } from '../assets/icons/soccer.svg';
import { ReactComponent as TableTennis } from '../assets/icons/table_tennis.svg';
import { ReactComponent as Tennis } from '../assets/icons/tennis.svg';
import { ReactComponent as Volleyball } from '../assets/icons/volleyball.svg';
import { ReactComponent as WaterPolo } from '../assets/icons/water_polo.svg';
import BadmintonHero from '../assets/sport-images/badminton.webp';
import BaseballHero from '../assets/sport-images/baseball.webp';
import BasketballHero from '../assets/sport-images/basketball.webp';
import BoxingHero from '../assets/sport-images/boxing.webp';
import CricketHero from '../assets/sport-images/cricket.webp';
import DartsHero from '../assets/sport-images/darts.webp';
import EsportsHero from '../assets/sport-images/esports.webp';
import FloorballHero from '../assets/sport-images/floorball.webp';
import FutsalHero from '../assets/sport-images/futsal.webp';
import HandballHero from '../assets/sport-images/handball.webp';
import IceHockeyHero from '../assets/sport-images/ice-hockey.webp';
import MMAHero from '../assets/sport-images/mma.webp';
import RugbyLeagueHero from '../assets/sport-images/rugby.webp';
import SnookerHero from '../assets/sport-images/snooker.webp';
import SoccerHero from '../assets/sport-images/soccer.webp';
import TableTennisHero from '../assets/sport-images/table-tennis.webp';
import TennisHero from '../assets/sport-images/tennis.webp';
import VolleyballHero from '../assets/sport-images/volleyball.webp';
import WaterPoloHero from '../assets/sport-images/waterpolo.webp';
import { Star } from '@mui/icons-material';

export const SPORTS_ASSETS_MAP = {
  badminton: { icon: Badminton, hero: BadmintonHero },
  baseball: { icon: Baseball, hero: BaseballHero },
  basketball: { icon: Basketball, hero: BasketballHero },
  boxing: { icon: Boxing, hero: BoxingHero },
  cricket: { icon: Cricket, hero: CricketHero },
  darts: { icon: Darts, hero: DartsHero },
  esports: { icon: Esports, hero: EsportsHero },
  floorball: { icon: Floorball, hero: FloorballHero },
  futsal: { icon: Futsal, hero: FutsalHero },
  golf: { icon: Golf, hero: '' },
  handball: { icon: Handball, hero: HandballHero },
  ice_hockey: { icon: IceHockey, hero: IceHockeyHero },
  mma: { icon: MMA, hero: MMAHero },
  rugby_league: { icon: RugbyLeague, hero: RugbyLeagueHero },
  snooker: { icon: Snooker, hero: SnookerHero },
  soccer: { icon: Soccer, hero: SoccerHero },
  table_tennis: { icon: TableTennis, hero: TableTennisHero },
  tennis: { icon: Tennis, hero: TennisHero },
  volleyball: { icon: Volleyball, hero: VolleyballHero },
  water_polo: { icon: WaterPolo, hero: WaterPoloHero },
  favorite: { icon: Star, hero: '' },
} as const;
