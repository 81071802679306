import { Grid, Stack, Typography } from '@mui/material';
import { Outcome, StyleObj } from '../../@types';
import { useBetslip } from '../../contexts/BetslipContext';

const makeStyles = (selected: boolean): StyleObj => ({
  outcomeCell: (theme) => ({
    py: 1.5,
    backgroundColor: selected ? 'neutral.100' : 'neutral.600',
    ':hover': {
      backgroundColor: !selected && 'neutral.400',
      cursor: 'pointer',
    },
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.neutral[700]}`,
    },
    '& p': {
      fontSize: {
        xs: 13,
        md: 15,
      },
    },
    transition: 'all 150ms ease-in-out',
  }),
});

type EventCardOutcomeCellProps = {
  outcome: Outcome;
  outcomeCount: number;
  onClick: () => void;
};

const EventCardOutcomeCell = ({ outcome, outcomeCount, onClick }: EventCardOutcomeCellProps) => {
  const { isOutcomeSelected } = useBetslip();
  const selected = isOutcomeSelected(outcome.id);

  const styles = makeStyles(selected);

  return (
    <Grid key={outcome.id} item xs={12 / outcomeCount} sx={styles.outcomeCell} onClick={onClick}>
      <Stack direction='row' justifyContent='center' spacing={1} px={1.5}>
        <Typography
          noWrap
          title={outcome.shortName}
          sx={styles.outcomeName}
          variant='body2'
          color={selected ? 'neutral.600' : 'inherit'}
        >
          {outcome.shortName}
        </Typography>
        <Typography variant='body2' color={selected ? 'primary' : 'secondary'}>
          {outcome.odds}
        </Typography>
      </Stack>
    </Grid>
  );
};

export default EventCardOutcomeCell;
