import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Stack, Typography } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import { useLocation } from 'react-router-dom';

const bettingHeaderLabels = [
  { title: 'Ticket Type', subtitle: 'Ticket ID' },
  { title: 'Date and time' },
  { title: 'Stake' },
  { title: 'Possible return' },
  { title: 'Return' },
];
const casinoHeaderLabels = [
  { title: 'Transaction' },
  { title: 'Date and time' },
  { title: 'Stake' },
  { title: 'Previous Balance' },
  { title: 'New Balance' },
];

type HeaderLabels = { title: string; subtitle?: string }[];

const BetHistoryTableHead = () => {
  const location = useLocation();
  const isCasino = location.pathname.includes('casino');
  const headerLabels: HeaderLabels = isCasino ? casinoHeaderLabels : bettingHeaderLabels;
  return (
    <TableHead>
      <TableRow>
        {headerLabels.map((label) => (
          <TableCell key={label.title}>
            <Stack alignItems='center'>
              <Typography variant='body2' fontWeight={600}>
                {label.title}
              </Typography>
              <Typography variant='body2'>{label.subtitle}</Typography>
            </Stack>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default BetHistoryTableHead;
