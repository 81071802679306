import { Box, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Outcome, PublishedEventListItem, StyleObj, TopMarketType } from '../../../@types';
import { useBetslip } from '../../../contexts/BetslipContext';
import OutcomeTableCell from '../../atoms/OutcomeTableCell';

type EventsTableProps = {
  data: PublishedEventListItem[];
  topMarketType?: TopMarketType;
  showTableHead?: boolean;
  firstCellHeader?: string;
  inPlay?: boolean;
};

const styles: StyleObj = {
  tableContainer: (theme) => ({
    backgroundColor: theme.palette.neutral[700],
    border: {
      xs: 'none',
      md: `1px solid ${theme.palette.neutral[600]}`,
    },
    boxShadow: 'none',
    borderRadius: 0,
  }),
  tableHead: {
    backgroundColor: 'primary.600',
    '& .MuiTableCell-head': {
      py: {
        xs: 1.25,
        md: 2,
      },
    },
  },
  participants: {
    textDecoration: 'none',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    '&:hover': {
      cursor: 'pointer',
      '& > *': {
        color: 'white',
      },
    },
    '> p': {
      typography: {
        xs: 'body3',
        md: 'body2',
      },
    },
  },
  timeAndMarketsWrapper: {
    display: 'flex',
    gap: 2,
    mt: 1,
    '> p': {
      typography: {
        xs: 'body4',
        md: 'body3',
      },
    },
  },
  tableRow: {
    '& .MuiTableCell-root': {
      py: {
        xs: 1,
        md: 1.5,
      },
    },
  },
  tableCell: (theme) => ({
    border: `1px solid ${theme.palette.neutral[600]}`,
    pl: {
      xs: 1,
      md: 3,
    },
    pr: {
      xs: 1,
      md: 2,
    },
    '&:hover': {
      '& .MuiTypography-body2': {
        color: theme.palette.info.main,
      },
    },
  }),
  outcomeHeaderFirstCell: {
    borderBottom: 'none',
    px: {
      xs: 1,
      md: 3,
    },
    '> p': {
      fontSize: {
        xs: 12,
        md: 16,
      },
    },
  },
  outcomeHeaderCell: {
    borderBottom: '4px solid white',
    '> p': {
      typography: {
        xs: 'h5',
        md: 'h3',
      },
    },
  },
  topMarketLink: {
    textDecoration: 'none',
    '&:hover': {
      color: 'white',
    },
    typography: {
      xs: 'body4',
      md: 'body3',
    },
  },
  emptyOutcomeCell: {
    borderBottom: '1px solid',
    borderColor: 'neutral.600',
    backgroundColor: 'neutral.800',
    opacity: 0.3,
    cursor: 'not-allowed',
  },
  score: {
    typography: {
      xs: 'body3',
      md: 'body2',
    },
  },
};

const EventsTable = ({ data, showTableHead, firstCellHeader, topMarketType, inPlay = false }: EventsTableProps) => {
  const { addOrRemoveBet } = useBetslip();

  const addToBetslip = (event: PublishedEventListItem, outcome: Outcome) => {
    if (!event.topMarket) return;

    addOrRemoveBet({
      outcomeId: outcome.id,
      odds: outcome.odds,
      eventName: event.name,
      marketId: event.topMarket.id,
      marketName: event.topMarket.shortName ?? event.topMarket.name,
      outcomeName: outcome.name,
      outcomeShortName: outcome.shortName,
      eventId: event.id,
      specialValues: event.topMarket.specialValues,
      marketType: event.topMarket.marketType,
      isLive: event.isLive,
    });
  };

  const outcomeTypes = topMarketType?.outcomeTypes;

  const getEventLink = (eventId: string) => (inPlay ? `/in-play/match-view/${eventId}` : `/sports/events/${eventId}`);

  return (
    <TableContainer component={Paper} sx={styles.tableContainer}>
      <MuiTable aria-label='events table'>
        {showTableHead && (
          <TableHead sx={styles.tableHead}>
            <TableRow>
              <TableCell sx={styles.outcomeHeaderFirstCell}>
                <Typography fontWeight={600}>{firstCellHeader}</Typography>
              </TableCell>
              {outcomeTypes?.map((outcomeType) => (
                <TableCell key={outcomeType.id} align='center' sx={styles.outcomeHeaderCell} width='16.6%'>
                  <Typography>{outcomeType.shortName ?? outcomeType.name}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id} sx={styles.tableRow}>
              <TableCell component='th' scope='row' sx={styles.tableCell}>
                <Stack direction='row' justifyContent='space-between' gap={2}>
                  <Stack>
                    <Box sx={styles.participants} component={Link} to={getEventLink(row.id)}>
                      <Typography>{row.participants.home?.name}</Typography>
                      <Typography>{row.participants.away?.name}</Typography>
                    </Box>
                    <Box sx={styles.timeAndMarketsWrapper}>
                      <Typography color='neutral.200'>
                        {row.matchTime ?? dayjs(row.startDate).format('ddd HH:mm')}
                      </Typography>
                      <Typography
                        component={Link}
                        to={`/sports/events/${row.id}`}
                        color='neutral.200'
                        sx={styles.topMarketLink}
                      >
                        {row.totalMarkets}&gt;
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack textAlign='right' spacing={1}>
                    <Typography sx={styles.score}>{row.mainEventPartScore?.valueHome}</Typography>
                    <Typography sx={styles.score}>{row.mainEventPartScore?.valueAway}</Typography>
                  </Stack>
                </Stack>
              </TableCell>
              {outcomeTypes?.map((outcomeType) => {
                const outcome = row?.topMarket?.outcomes?.find((item) => item.outcomeTypeId === outcomeType.id);
                if (!outcome) {
                  return (
                    <TableCell key={outcomeType.id} sx={styles.emptyOutcomeCell}>
                      <Typography textAlign='center'> - </Typography>
                    </TableCell>
                  );
                }
                return (
                  <OutcomeTableCell
                    key={outcome.id}
                    outcome={outcome}
                    onClick={() => addToBetslip(row, outcome)}
                    isMarketSingleDisplayType={row.topMarket?.isSingleDisplayType ?? false}
                    hideOutcomeName
                  />
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default EventsTable;
